.c-main-content .p-portfolio--detailed {
  display: none; }

.c-main-content .p-portfolio--cards {
  display: none; }

.c-main-content._c-switcher--selected-detailed .p-portfolio--detailed {
  display: block; }

.c-main-content._c-switcher--selected-cards .p-portfolio--cards {
  display: block; }
